import React, {useState} from 'react';
import {classes} from './Summary.st.css';
import {FoldableSummarySection} from '../../../common/components/FoldableSummarySection/FoldableSummarySection';
import {PaymentRequestItemSection} from './PaymentRequestItemSection/PaymentRequestItemSection';
import {PaymentRequestTotals} from './PaymentRequestTotals/PaymentRequestTotals';

export interface PaymentRequestMobileSummaryProps {
  total: string;
}

export const PaymentRequestMobileSummary = ({total}: PaymentRequestMobileSummaryProps) => {
  const [isFoldableSummarySectionOpen, setIsFoldableSummarySectionOpen] = useState(false);

  const toggleFoldableSummarySection = () => {
    const isSummaryOpen = !isFoldableSummarySectionOpen;
    setIsFoldableSummarySectionOpen(isSummaryOpen);
  };

  return (
    <>
      <FoldableSummarySection
        onClick={toggleFoldableSummarySection}
        isOpen={isFoldableSummarySectionOpen}
        shouldShowErrorIcon={false}
        total={total}
      />
      {isFoldableSummarySectionOpen && (
        <div className={classes.topSummaryWithFoldableSection}>
          <PaymentRequestItemSection />
          <PaymentRequestTotals total={total} />
        </div>
      )}
    </>
  );
};
