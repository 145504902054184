import React, {useEffect, useState} from 'react';
import {HeaderLoader} from '../../../common/components/Layout/HeaderLoader/HeaderLoader';
import {pageTheme} from '../../../common/components/WithThemeOverride/pageTheme';
import {MainLoader} from '../../../common/components/Layout/MainLoader/MainLoader';
import {Header} from '../Header/Header';
import {classes} from './PaymentRequestViewLayout.st.css';
import {useControllerProps} from '../Widget/ControllerContext';
import {ThankYouView} from '../PaymentRequestView/ThankYouView/ThankYouView';
import {PaymentRequestState} from '../../../types/paymentRequestApp.types';

export interface LayoutProps {
  isLoading: boolean;
  dir: string;
}

export enum PaymentRequestViewLayoutDataHooks {
  root = 'PaymentRequestViewLayoutDataHooks.root',
  header = 'PaymentRequestViewLayoutDataHooks.header',
}

export const PaymentRequestViewLayout = ({dir, isLoading}: LayoutProps) => {
  const [loaded, setLoaded] = useState(false);
  const {
    navigationStore: {paymentRequestState},
  } = useControllerProps();

  useEffect(() => {
    setLoaded(!isLoading);
  }, [isLoading]);

  const getView = () => {
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (paymentRequestState) {
      case PaymentRequestState.paid:
        return <ThankYouView />;
      default:
        return null;
    }
  };

  return (
    <div data-hook={PaymentRequestViewLayoutDataHooks.root} className={classes.root} dir={dir} style={pageTheme}>
      {loaded ? <Header dataHook={PaymentRequestViewLayoutDataHooks.header} /> : <HeaderLoader />}
      <div className={classes.container}>
        <section className={classes.main}>{loaded ? getView() : <MainLoader />}</section>
      </div>
    </div>
  );
};
