/* istanbul ignore file */
import {PaymentsWidgetConfiguration} from '@wix/cashier-payments-widget/dist/src/types/PaymentsWidgetConfiguration';
import {PaymentsWidgetProps} from '@wix/cashier-payments-widget/dist/src/types/PaymentsWidgetProps';
import {PaymentRequestSettingsModel} from '../domain/models/paymentRequest/PaymentRequestSettings.model';
import {PaymentRequestModel} from '../domain/models/paymentRequest/PaymentRequest.model';

export type PaymentRequestControllerProps = {
  isOneColumnView?: boolean;
  isLoading: boolean;
  failedToFetch?: boolean;
  isPaid?: boolean;
  paymentRequestStore: PaymentRequestStoreProps;
  paymentRequestSettingsStore: PaymentRequestSettingsStoreProps;
  paymentStore: PaymentRequestPaymentStoreProps;
  navigationStore: PaymentRequestNavigationStoreProps;
};

export enum PaymentFlowMonitorBreadcrumbs {
  validation = 'cashier billing form is valid',
  paid = 'completed payment',
  error = 'payment error',
}

export enum PaymentResult {
  Approved = 'Approved',
  Pending = 'Pending',
}

export enum PaymentRequestState {
  paid = 'Paid',
  unpaid = 'Unpaid',
  expired = 'Expired',
  error = 'Error',
}

export interface PaymentRequestSettingsStoreProps {
  paymentRequestSettings: PaymentRequestSettingsModel;
}

export interface PaymentRequestPaymentStoreProps {
  cashierWidgetProps: PaymentsWidgetProps;
  cashierConfiguration: PaymentsWidgetConfiguration;
}

export interface PaymentRequestStoreProps {
  paymentRequest: PaymentRequestModel;
}

export interface PaymentRequestNavigationStoreProps {
  navigateToThankYouPage: () => void;
  continueShoppingUrl: string;
  isSSR: boolean;
  paymentRequestState?: PaymentRequestState;
}
