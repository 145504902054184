import {PaymentsWidget} from '@wix/cashier-payments-widget/lazy';
import React from 'react';
import {useControllerProps} from '../Widget/ControllerContext';
import {usePaymentsApi} from '../../../common/components/WithPaymentsApi/WithPaymentsApi';
import {classes} from './PaymentFlow.st.css';

export enum PaymentWidgetWrapperDataHooks {
  root = 'PaymentWidgetWrapperDataHooks.root',
  emptyRoot = 'PaymentWidgetWrapperDataHooks.emptyRoot',
}

export const PaymentWidgetWrapper = () => {
  const {
    paymentStore: {cashierWidgetProps},
    navigationStore: {isSSR},
  } = useControllerProps();

  const {setPaymentsApi} = usePaymentsApi();
  const {configuration, ...cashierProps} = cashierWidgetProps;

  /* istanbul ignore if */
  if (!cashierWidgetProps || isSSR) {
    return <div className={classes.paymentEmpty} data-hook={PaymentWidgetWrapperDataHooks.emptyRoot}></div>;
  }

  return (
    <div className={classes.paymentOpen} data-hook={PaymentWidgetWrapperDataHooks.root}>
      <PaymentsWidget
        {...cashierProps}
        configuration={{...configuration}}
        isFullWidth={true}
        onApiInit={setPaymentsApi}
      />
    </div>
  );
};
