import React from 'react';
import {Text} from 'wix-ui-tpa/cssVars';
import {PaymentRequestView} from '../PaymentRequestView';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../../Widget/ControllerContext';
import {classes} from './ThankYouView.st.css';

export enum ThankYouViewDataHook {
  root = 'ThankYouViewDataHook.root',
  view = 'ThankYouViewDataHook.view',
  orderNumber = 'ThankYouViewDataHook.orderNumber',
  amount = 'ThankYouViewDataHook.amount',
}

export const ThankYouView = () => {
  const {
    paymentRequestStore: {paymentRequest},
  } = useControllerProps();
  const localeKeys = useLocaleKeys();
  const orderNumber = paymentRequest.orderNumber;
  const amountPaid = paymentRequest.total.formattedAmount;

  return (
    <div data-hook={ThankYouViewDataHook.root}>
      <PaymentRequestView
        dataHook={ThankYouViewDataHook.view}
        title={localeKeys.checkout.payLinks.thankYouPage.title()}
        subtitle={localeKeys.checkout.payLinks.thankYouPage.subtitle()}
      />
      <div className={classes.numberAndAmountContainer}>
        {orderNumber && (
          <Text data-hook={ThankYouViewDataHook.orderNumber} className={classes.thankYouText}>
            {localeKeys.checkout.payLinks.thankYouPage.orderNumber({incrementalID: orderNumber})}
          </Text>
        )}
        {amountPaid && (
          <Text data-hook={ThankYouViewDataHook.amount} className={classes.thankYouText}>
            {localeKeys.checkout.payLinks.thankYouPage.amountPaid({amountPaid})}
          </Text>
        )}
      </div>
    </div>
  );
};
