import React from 'react';
import {PaymentWidgetWrapper} from './PaymentWidgetWrapper';
import {classes} from './PaymentFlow.st.css';
import {classes as commonClasses} from '../../../common/common.st.css';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {Text, TextPriority} from 'wix-ui-tpa/cssVars';
import {PayNowButton} from './PayNowButton/PayNowButton';

export enum PaymentFlowDataHook {
  root = 'PaymentFlowDataHook.root',
  title = 'PaymentFlowDataHook.title',
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const PaymentFlow = () => {
  const localeKeys = useLocaleKeys();

  return (
    <div className={commonClasses.oneColumnViewPadding} data-hook={PaymentFlowDataHook.root}>
      <div className={classes.stepTitleContainer}>
        <div className={classes.stepTitle}>
          <Text
            tagName={'h2'}
            priority={TextPriority.primary}
            className={classes.titleText}
            data-hook={PaymentFlowDataHook.title}>
            {localeKeys.checkout.payment()}
          </Text>
        </div>
      </div>
      <PaymentWidgetWrapper />
      <PayNowButton />
    </div>
  );
};
